import React, { useEffect, useState } from "react";

type rangeProps = {
  name: string
  func: Function
}

function Range(props:rangeProps) {
  // console.log(props.name);
  
  const [value, setValue] = useState(0);
  const handleOnChange = (e:any) => {
    setValue(e.target.value);
  };

  const name = props.name.replace(/ /g, '');

  useEffect(() => {
    props.func(name, [{id:0, name:value}]);
  }, [value])

  return (
    <>
      <div className="col-md-6 py-2">
        <div className="d-flex justify-content-between align-items-center bg-danger rounded-top p-2">
          <span>{props.name}</span>
          <span className="user-select-none">{value}</span>
        </div>
        <div className="d-flex justify-content-between bg-warning rounded-bottom p-2">
          <input
            id={props.name}
            type="range"
            className="form-range"
            onChange={(e) => handleOnChange(e)}
            defaultValue={0}
            min="0"
            max="10"
          ></input>
        </div>
      </div>
    </>
  );
}

export default React.memo(Range)