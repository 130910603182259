import React, { useState, useEffect, useCallback, useMemo } from "react";

type NoOfRecordProps = {
  name: string;
  func: Function;
};
function NoOfRecord(props: NoOfRecordProps) {
  // console.log(props.name);
  const [value, setValue] = useState<string>('100');
  const handleOnChange = useCallback(
    (e: any) => {
      let v = e.target.value;
      setValue(v);
    },
    [value]
  );

  const name = props.name.replace(/ /g, "");
  useEffect(() => {
    props.func(name, [{ id: 0, name: value }]);
  }, [value]);

  return (
    <>
      <div className="col-md-6 py-2">
        <div className="d-flex justify-content-between align-items-center bg-danger rounded-top p-2">
          <span>{props.name}</span>
          <span className="user-select-none">{value}</span>
        </div>
        <div className="d-flex justify-content-between bg-warning rounded-bottom p-2">
          <input
            id={props.name}
            type="number"
            className="text"
            onChange={(e) => handleOnChange(e)}
            value={value}
          ></input>
        </div>
      </div>
    </>
  );
}

export default React.memo(NoOfRecord);
