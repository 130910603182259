import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home"
import FilterPage from "./pages/filterpage/FilterPage";
import QuesSelectionPage from "./pages/quesSelection/QuesSelectionPage";
import PreviewPage from "./pages/preview/PreviewPage";
import PresentationPage from "./pages/presentation/PresentationPage";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/filterpage" element={<FilterPage />} />
          <Route path="/selectionpage" element={<QuesSelectionPage />} />
          <Route path="/previewpage" element={<PreviewPage url="https://freekey-api.bitss.in/api/base/GetQuestions" />} />
          <Route path="/presentationpage" element={<PresentationPage url="https://freekey-api.bitss.in/api/base/GetQuestions" />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
