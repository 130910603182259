import React, { useRef, useEffect, useState, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Import Icons

import { GiPencil } from "react-icons/gi";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { BsFiletypePdf, BsFiletypePpt } from "react-icons/bs";
import { BiCommentEdit } from "react-icons/bi";

type quesSet = {
  id: string;
  setName: string;
  accessCode: string;
  accessPassword: string;
  accessAllowEdit: boolean;
  createdBy: string;
  createDateUTC: string;
  modifiedDateUTC: any;
  categories: string;
  totalQuestions: number;
};

function Home() {
  const getQuestionSet_Api =
    "https://ms-teachami.prepami.com/api/question/getquestionsets";
  const getQuestionSetById_Api =
    "https://ms-teachami.prepami.com/api/question/GetQuestionSetById?SetID=";
  const createQuestionSet_Api =
    "https://ms-teachami.prepami.com/api/question/CreateQuestionSet";
  const getQuestions_Api = "https://freekey-api.bitss.in/api/base/GetQuestions";
  const quesSetName = useRef<any>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allSets, setAllSets] = useState<quesSet[]>();
  const [quesSet, setQuesSet] = useState<quesSet[]>();

  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [forFilter, setForFilter] = useState<string>("All");

  const getQuestionSet = async () => {
    setIsLoading(true);

    try {
      const data = await axios.get(getQuestionSet_Api, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
        },
      });
      setAllSets(data.data);
      getAllCategories(data.data);
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCategories = (quesSets: []) => {
    let s = "";
    quesSets?.map((item: quesSet) => {
      if (item.categories) s += item.categories + ", ";
    });
    // quesSet?.join();
    let a1 = s.split(",");
    let a2: string[] = [];
    setCategoryList(a2);
    a1.map((e: string) => {
      if (!a2.includes(e.trim())) {
        a2.push(e.trim());
      }
    });
    a2.sort();
    setCategoryList(a2);
  };

  const getQuestions = async (list: any) => {
    setIsLoading(true);

    try {
      const resp = await axios.post(getQuestions_Api, list);
      return resp.data;
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
    return [];
  };
  class GetQuestionSetById_DTO {
    id: string = "";
    setName: string = "";
    accessCode: string = "";
    accessPassword: string = "";
    accessAllowEdit: string = "";
    createdBy: string = "";
    createdDateUTC: string = "";
    modifiedDateUTC: string = "";
    categories: string = "";
    questionSetItemsList: {
      id: string;
      questionID: number;
      serialNumber: number;
    }[] = [];
  }
  const getQuestionSetById = async (id: string) => {
    setIsLoading(true);
    let questions: GetQuestionSetById_DTO = new GetQuestionSetById_DTO();
    try {
      const data = await axios.get<GetQuestionSetById_DTO>(
        getQuestionSetById_Api + id,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
          },
        }
      );
      questions = data.data;
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }

    return questions;
  };

  const handleCreateSet = async () => {
    const value = quesSetName.current.value;
    setIsLoading(true);

    try {
      const data = await axios.post(
        createQuestionSet_Api,
        { setName: value },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
          },
        }
      );
      getQuestionSet();
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditBtnClick = (
    id: string,
    setName: string,
    categories: string
  ) => {
    // alert(id);
    (document.getElementById("updateSetName") as HTMLInputElement).value =
      setName;
    (document.getElementById("updateSetCategory") as HTMLInputElement).value =
      categories;
    (document.getElementById("updateSetId") as HTMLInputElement).value = id;
  };

  const handleUpdateSet = async () => {
    let id = (document.getElementById("updateSetId") as HTMLInputElement).value;
    setIsLoading(true);
    let a: any = {
      SetID: id,
      UpdateSetName: (
        document.getElementById("updateSetName") as HTMLInputElement
      ).value,
      Categories: (
        document.getElementById("updateSetCategory") as HTMLInputElement
      ).value,
    };

    const formData = new FormData();
    Object.keys(a).forEach((key) => formData.append(key, a[key]));

    try {
      const data = await axios.post(
        "https://ms-teachami.prepami.com/api/question/UpdateQuestionSetCategories",
        formData,
        {
          headers: {
            "Content-Type": "x-www-form-urlencoded",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
          },
        }
      );
      // alert("updated");
      getQuestionSet();
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleAddQues = (id: string, name: string) => {
    navigate("/filterpage", {
      state: { setId: id, setName: name },
    });
  };
  const handlePPTQues = async (id: string) => {
    let Q = await getQuestionSetById(id);
    if (Q.questionSetItemsList.length) {
      let allQ = await getQuestions(Q.questionSetItemsList);
      navigate("/presentationpage", {
        state: { setId: id, ques: allQ },
      });
    }
  };
  const handlePDFQues = async (id: string) => {
    let Q = await getQuestionSetById(id);
    if (Q.questionSetItemsList.length) {
      let allQ = await getQuestions(Q.questionSetItemsList);
      navigate("/previewpage", {
        state: { setId: id, ques: allQ },
      });
    }
  };
  const openEditQuesSet = async (id: string, name: string) => {
    let Q = await getQuestionSetById(id);
    if (Q.questionSetItemsList.length) {
      let allQ = await getQuestions(Q.questionSetItemsList);
      navigate("/selectionpage", {
        state: { setId: id, setName: name, ques: allQ, redirect: "homePage" },
      });
    }
  };

  const [forSearch, setForSearch] = useState<string>("");

  useEffect(() => {
    setQuesSet(allSets);
  }, [allSets]);

  useEffect(() => {
    getQuestionSet();
  }, []);

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Home
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => setForFilter("All")}
                    >
                      All
                    </a>
                  </li>
                  {categoryList?.map((item: string, index: number) => {
                    return (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => setForFilter(item)}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>

      <div className="row d-flex justify-content-between bg-light p-2">
        <div className="col-md-auto text-center">
          <ul
            className="navbar-nav px-1 mb-2 mb-lg-0 border border-dark rounded"
            style={{ width: "-webkit-fill-available" }}
          >
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Filter by Category: {forFilter}
              </a>
              <ul
                className="dropdown-menu text-center"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => setForFilter("All")}
                  >
                    All
                  </a>
                </li>
                {categoryList?.map((item: string, index: number) => {
                  return (
                    <li key={index}>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => setForFilter(item)}
                      >
                        {item}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </div>
        <div className="col-md-auto col-7 mb-3">
          <input
            type="email"
            className="form-control"
            placeholder="Search for Set Name"
            onChange={(e) => setForSearch(e.target.value)}
            value={forSearch}
          />
        </div>
        <div className="col-md-auto text-end col-5">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createQuesSet"
          >
            Create Set
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id="createQuesSet"
        tabIndex={-1}
        aria-labelledby="createQuesSetLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createQuesSetLabel">
                Create new set
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                ref={quesSetName}
                placeholder="Enter set name"
              />
            </div>
            <div className="modal-footer p-1 mx-auto d-flex align-items-center">
              <button
                type="button"
                onClick={() => handleCreateSet()}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3 px-2 pt-3">
        {isLoading && (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!isLoading &&
          quesSet &&
          quesSet.map((item: quesSet, index: number) => {
            if (
              (forFilter == null ||
                forFilter == "All" ||
                item.categories?.includes(forFilter)) &&
              (forSearch.trim() == "" || item.setName?.includes(forSearch))
            ) {
              return (
                <>
                  <div
                    key={index}
                    className="col-md-4 d-none d-sm-block m-0 p-0"
                  >
                    <div className="m-1 p-4 text-primary border border-dark rounded text-center">
                      <h3
                        data-bs-toggle="modal"
                        data-bs-target="#update_set_modal"
                        onClick={() =>
                          handleEditBtnClick(
                            item.id,
                            item.setName,
                            item.categories
                          )
                        }
                      >
                        {item.setName.length > 15
                          ? `${item.setName.substring(0, 15)}...`
                          : item.setName}{" "}
                        <GiPencil style={{ fontSize: "25px" }} />
                      </h3>
                      <span className="mx-1 px-2 pb-1 border border-secondary rounded-pill">
                        Total: {item.totalQuestions}
                      </span>
                      <span className="mx-1 px-2 pb-1 border border-secondary rounded-pill">
                        {item.categories ? item.categories : "none"}
                      </span>

                      <div className="row pt-3">
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary m-1"
                            onClick={() =>
                              openEditQuesSet(item.id, item.setName)
                            }
                          >
                            <BiCommentEdit style={{ fontSize: "25px" }} />
                          </button>

                          <button
                            onClick={() => handleAddQues(item.id, item.setName)}
                            className="btn btn-primary m-1"
                          >
                            <AiOutlineFolderAdd style={{ fontSize: "25px" }} />
                          </button>
                          <button
                            onClick={() => handlePPTQues(item.id)}
                            className="btn btn-primary m-1"
                          >
                            <BsFiletypePpt style={{ fontSize: "25px" }} />
                          </button>
                          <button
                            onClick={() => handlePDFQues(item.id)}
                            className="btn btn-primary m-1"
                          >
                            <BsFiletypePdf style={{ fontSize: "25px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    key={index + "a"}
                    className="col-md-4 d-sm-none border border-secondary rounded mb-2 p-0 mx-auto"
                  >
                    <div className="row p-1">
                      <div className="col-2 d-flex justify-content-start align-items-center">
                        <span className=" rounded bg-primary text-light mx-2 px-2 pb-1">
                          {index + 1}
                        </span>
                      </div>
                      <div className="col-10 row d-flex flex-column">
                        <div className=" p-0">
                          <span
                            className="m-0"
                            data-bs-toggle="modal"
                            data-bs-target="#update_set_modal"
                            onClick={() =>
                              handleEditBtnClick(
                                item.id,
                                item.setName,
                                item.categories
                              )
                            }
                          >
                            {item.setName.length > 25
                              ? `${item.setName.substring(0, 25)}...`
                              : item.setName}{" "}
                            <GiPencil style={{ fontSize: "15px" }} />
                          </span>
                          <br></br>
                          <span
                            className="me-1 px-2 pb-1 border border-secondary rounded-pill"
                            style={{ fontSize: "11px" }}
                          >
                            Total: {item.totalQuestions}
                          </span>
                          <span
                            className="me-1 px-2 pb-1 border border-secondary rounded-pill"
                            style={{ fontSize: "11px" }}
                          >
                            {item.categories ? item.categories : "none"}
                          </span>
                        </div>
                        <div className=" p-0 py-1 ">
                          <button
                            className=" btn"
                            onClick={() =>
                              openEditQuesSet(item.id, item.setName)
                            }
                          >
                            <BiCommentEdit
                              className="text-primary"
                              style={{ fontSize: "25px" }}
                            />
                          </button>
                          <button
                            onClick={() => handleAddQues(item.id, item.setName)}
                            className=" btn"
                          >
                            <AiOutlineFolderAdd
                              className="text-primary"
                              style={{ fontSize: "25px" }}
                            />
                          </button>
                          <button
                            onClick={() => handlePPTQues(item.id)}
                            className=" btn"
                          >
                            <BsFiletypePpt
                              className="text-primary"
                              style={{ fontSize: "25px" }}
                            />
                          </button>
                          <button
                            onClick={() => handlePDFQues(item.id)}
                            className=" btn"
                          >
                            <BsFiletypePdf
                              className="text-primary"
                              style={{ fontSize: "25px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        <div
          className="modal fade"
          id="update_set_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Question Set</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <input type="hidden" id="updateSetId"></input>
              <div className="modal-body">
                <div className="mb-3 text-start">
                  <p>Name :</p>
                  <input
                    type="text"
                    id="updateSetName"
                    className="form-control"
                    placeholder="Enter set name"
                  />
                </div>
                <div className="mb-3 text-start">
                  <p>Category :</p>
                  <input
                    type="text"
                    id="updateSetCategory"
                    className="form-control"
                    placeholder="Enter Categories"
                  />
                </div>
              </div>
              <div className="modal-footer p-1 mx-auto d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => handleUpdateSet()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Home);
