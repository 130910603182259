import React from "react";
import { QuesPreviousYear } from "../../../dtos/searchQuestions/QuesPreviousYear";

type PreviousYearTag = {
  year: QuesPreviousYear[];
};

function PreviousYearTag(props: PreviousYearTag) {
  // console.log(year[0].year);
  return (
    <div className="text-end my-2">
      {props.year?.map((y, index) => {
        return (
          <span key={index} className="bg-warning rounded-pill px-2 py-1 ms-1">
            {y.examName ? y.examName + "-" + y.year : y.year}
          </span>
        );
      })}
    </div>
  );
}
export default React.memo(PreviousYearTag);
