import React, { useEffect, useState } from "react";
import axios from "axios";
import { GetApiResponseDto } from "../../../dtos/GetApiResponseDto";

type OptionUsingApiProps = {
  name: string;
  url: string;
  func: Function;
};

type onFilter = {
  id: number;
  name: string;
}[];

function OptionUsingApi(props: OptionUsingApiProps) {
  // console.log(props.name);
  const [apiData, setApiData] = useState<GetApiResponseDto[] | null>(null);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [searchFor, setSearchFor] = useState<any>("");
  const [isSelected, setIsSelected] = useState<any[]>([]);
  const [className, setClassName] = useState<string>(
    "border border-dark rounded-pill m-1 p-2"
  );
  const [onFilter, setOnFilter] = useState<onFilter | null>(null);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get<GetApiResponseDto[]>(`${props.url}`, {
         headers: {
           Accept: 'application/json',
           Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
         },
      });
      setApiData(data.data);
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFor(e.target.value);
  };

  const handleSelect = (id: number) => {
    if (!isSelected.includes(id)) {
      setIsSelected([...isSelected, id]);
    } else {
      setIsSelected(isSelected?.filter((el: any) => el !== id));
    }
  };

  var filteredArray: any = [];
  const handleResetBtn = () => {
    setIsSelected([]);
    setClassName("border border-dark rounded-pill m-1 p-2");
  };
  const handleApplyBtn = () => {
    filteredArray = apiData?.filter(function (el: any) {
      return (
        isSelected?.filter(function (item: any) {
          return item === el.id;
        }).length === 0
      );
    });
    filteredArray = apiData?.filter(function (el: any) {
      return (
        filteredArray?.filter(function (item: any) {
          return item.id === el.id;
        }).length === 0
      );
    });
    setOnFilter(filteredArray);
  };

  useEffect(() => {
    props.func(props.name, onFilter);
  }, [onFilter]);

  const name = props.name.replace(/ /g, "");
  const d = "#" + name;
  const id = name;
  const label = name + "Label";

  return (
    <>
      <div className="col-md-6 py-2">
        <div className="d-flex justify-content-between align-items-center bg-danger rounded-top p-2">
          <span>{props.name}</span>
          <span
            className="user-select-none"
            onClick={() => handleClick()}
            data-bs-toggle="modal"
            data-bs-target={d}
          >
            filter
          </span>
        </div>
        <div
          className="modal fade"
          id={id}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby={label}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id={label}></h5> */}
                <input
                  className="form-control me-2 w-50"
                  type="search "
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                  value={searchFor}
                  aria-label="Search"
                ></input>
                <button
                  type="button"
                  className="btn-close border border-dark rounded-circle me-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {isLoading && <p className="text-center">Loading...</p>}
              <p className="text-center mb-0">
                Total Selected: {isSelected && isSelected.length}
              </p>
              <div className="modal-body d-flex flex-wrap">
                {apiData?.map((item: GetApiResponseDto) => {
                  return (
                    <p
                      key={item.id}
                      className={`${className} ${
                        isSelected?.includes(item.id) ? "bg-info" : ""
                      }`}
                      onClick={(e) => handleSelect(item.id)}
                      data-select="false"
                    >
                      {item.name}
                    </p>
                  );
                })}
              </div>
              <div className="modal-footer rounded-pill p-1 shadow m-2 mx-auto bg-light w-40 d-flex align-items-center">
                <button
                  type="button"
                  onClick={() => handleResetBtn()}
                  className="btn rounded-pill"
                >
                  Reset
                </button>
                <button
                  type="button"
                  onClick={() => handleApplyBtn()}
                  className="btn btn-primary rounded-pill"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap bg-warning rounded-bottom p-2">
          {onFilter?.map((item: any) => {
            return (
              <div
                key={item.id}
                className="border border-dark rounded-pill m-1 px-2"
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default React.memo(OptionUsingApi);
