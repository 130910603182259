import React, { useState, useEffect, Component } from "react";
import axios from "axios";

import { useLocation } from "react-router-dom";

// Import components
import Header from "./components/Header";
import Question from "./components/Question";
import Options from "./components/Options";
import Solution from "./components/Solution";
import PreviousYearTag from "./components/PreviousYearTag";
import AnswerKeyWidget from "./components/AnswerKeyWidget";

import "jspdf/dist/polyfills.es.js";
import JsPDF from "jspdf";
import "./font.js";
import { SearchQuesitonDto } from "../../dtos/searchQuestions/SearchQuestionsDto";

type PreviewPageProps = {
  url: string;
};

function PreviewPage(props: PreviewPageProps) {
  let previewpageData = useLocation();
  // console.log(d.state.ques);
  const [data, setData] = useState<SearchQuesitonDto[]>(
    previewpageData.state.ques
  );
  const [state, setState] = useState<number | string>(15);
  const [space, setSpacing] = useState<number | string>(10);
  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [isBilingual, setIsBilingual] = useState<boolean>(true);
  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [enableColumn, setEnableColumn] = useState<boolean>(false);
  const [preYear, setPreYear] = useState<boolean>(false);
  const [list, setList] = useState<any>();
  const [column, setColumn] = useState<string>("col-md-12 question");
  const [ansWidget, setAnsWidget] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get<any>(`${props.url}`)
      .then((res) => {
        return setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function showAllQuestions() {
    setList(
      data?.map((item: any, index: number) => {
        if (item == null) return <span>9</span>;
        return (
          <div
            key={index}
            className={column}
            style={{ padding: `20px 10px ${space}px 10px` }}
          >
            <Question ques={item} multilingual={isBilingual} fontSize={state} />
            {preYear && <PreviousYearTag year={item.previousYear} />}
            {showOptions && (
              <Options
                opt={item.options}
                multilingual={isBilingual}
                ans={showAnswer}
              />
            )}
            {showSolution && (
              <Solution sol={item.solution} multilingual={isBilingual} />
            )}
          </div>
        );
      })
    );
    setTimeout(() => {
      (window as any).MathJax.typesetPromise();
    }, 500);
  }
  // export class y{

  // }
  const handleShowAnsWidget = (e: any) => {
    setAnsWidget(e.target.checked);
  };
  const handleHiglightAnswer = (e: any) => {
    setShowAnswer(e.target.checked);
  };

  const handleColumn = () => {
    setEnableColumn(!enableColumn);
    if (enableColumn === true) {
      setColumn("col-md-12 question");
    } else {
      setColumn("col-md-6 question");
    }
  };

  const handleShowSolution = (e: any) => {
    setShowSolution(e.target.checked);
  };

  const handleShowOptions = (e: any) => {
    setShowOptions(e.target.checked);
  };

  const handleShowPreYear = (e: any) => {
    setPreYear(e.target.checked);
  };

  const handleBilingual = (e: any) => {
    setIsBilingual(e.target.checked);
  };

  useEffect(() => {
    showAllQuestions();
  }, [
    data,
    state,
    space,
    isBilingual,
    preYear,
    showOptions,
    showAnswer,
    showSolution,
    enableColumn,
    ansWidget,
  ]);

  //    const handleImportPDF = () => {
  //      alert("pdf");

  //       const report = new JsPDF("portrait", "pt", "a4");

  //        report.text('<b>तुलना में कम प्रकाश प्रदूषण का कार</b>', 10,50);
  //        report.save('table.pdf');
  //       report.addFileToVFS("Arya-Regular.ttf", window.amiriFont);
  //       report.addFont("Arya-Regular.ttf", "Arya", "regular");
  //       report.setFont("Arya");
  //       report
  //         .html(
  //           '<!DOCTYPE html><html ><head><meta charset="UTF-8"></head><body style="font-family:\'Arya\' !important;">1234 तुलना में कम प्रकाश प्रदूषण का कार</body></html>',
  //           { fontFaces: [{ family: "Arya" }] }
  //         )
  //         .then(() => {
  //           report.addFileToVFS("Arya-Regular.ttf", window.amiriFont);
  //           report.addFont("Arya-Regular.ttf", "Arya", "regular");
  //           report.setFont("Arya");
  //           report.save("report2.pdf");
  //         });

  //       report
  //         .html(document.getElementById("list_of_questions"), {
  //           fontFaces: [{ family: "Arya" }],
  //         })
  //         .then(() => {
  //             report.save('report.pdf');
  //         });
  //    };

  return (
    <>
      <div className="container preview_page">
        <div className="row">
          <div className="col mt-4">
            <nav className="navbar sticky-top bg-body-tertiary">
              <div className="container-fluid">
                <a href="" className="navbar-brand">
                  MCQ's :-
                </a>
                <div>
                  <button
                    className="btn btn-primary ms-1"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasEdit"
                    aria-controls="offcanvasEdit"
                  >
                    Edit
                  </button>
                  {/* <button
                                     className="btn btn-primary ms-1"
                                     type="button"
                                     onClick={handleImportPDF}
                                     >
                                     Import as PDF
                                     </button> */}
                </div>
              </div>
            </nav>
            <div
              className="offcanvas offcanvas-start"
              tabIndex={-1}
              id="offcanvasEdit"
              aria-labelledby="offcanvasEditLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasEditLabel">
                  PDF Configuration
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                {/* <h2></h2> */}
                <label htmlFor="customRange2" className="form-label">
                  Font Size: {state}
                </label>
                <input
                  type="range"
                  className="form-range"
                  min="10"
                  max="50"
                  id="customRange2"
                  defaultValue={state}
                  onChange={(e) => setState(e.target.value)}
                ></input>
                <label htmlFor="customRange2" className="form-label">
                  Spacing: {space}
                </label>
                <input
                  type="range"
                  className="form-range"
                  min="10"
                  max="50"
                  id="customRange2"
                  defaultValue={space}
                  onChange={(e) => setSpacing(e.target.value)}
                ></input>

                <div className="form-check form-switch">
                  <label>Bilingual PDF</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={isBilingual}
                    onClick={handleBilingual}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Previous Year Tag</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={handleShowPreYear}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Show Option</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={showOptions}
                    onChange={handleShowOptions}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Highlight Answer</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={handleHiglightAnswer}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Show Solution</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={handleShowSolution}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Show in Two Columns</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={handleColumn}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
                <div className="form-check form-switch">
                  <label>Show Answer Widget</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onClick={handleShowAnsWidget}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
            </div>
            <div
              id="list_of_questions"
              className="row px-3"
              style={{ fontSize: `${state}px` }}
            >
              <Header
                h3={"Utkarsh Classes"}
                span1={"12341234"}
                span2={"asd asd"}
                span3={"By Anurag Sanadhya"}
              />
              {list}
              {data && ansWidget && <AnswerKeyWidget anskey={data} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(PreviewPage);
