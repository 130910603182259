import { Type } from "class-transformer"
import { QuesOptTranslation } from "./QuesOptTranslations"
import { QuesOptions } from "./QuesOptions"
import { QuesPreviousYear } from "./QuesPreviousYear"
import { QuesSolution } from "./QuesSolution"
import { QuestionTitle } from "./QuestionTitle"

export class SearchQuesitonDto {
    questionID: number = 0
    questionNo: number = 0
    questionTitle: QuestionTitle[] = []
    options: QuesOptions[] = []
    subject: string = ''
    chapter: string = ''
    topic: string = ''
    subTopic: string = ''
    previousYear: QuesPreviousYear[] = []
    solution: QuesSolution[] = []
    level: number = 0
    averageTimeSecs: number = 0
    maxTimeSecs: number = 0
    importanceLevel: number = 0
    examId: number = 0
    nature: string = ''

    isSelected:boolean=false


    GetQuestionTitle(lang:string):QuestionTitle {
        var res= this.questionTitle.filter((x)=>{
            return x.lang === lang;
        });

        if(res.length>0){
            return res[0];
        }
        else if(this.questionTitle.length>0){
           return this.questionTitle[0];
        }
        return new QuestionTitle();
    }

   

    GetOptions(lang:string):OptionView[] {

        var translations = this.options.map((x) => {
            
            var translation = this.GetOptionTranslation(lang,x);
           
            var y:OptionView = {
                optionID:x.optionID,
                fraction:x.fraction,
                lang:lang,
                optionText:translation.optionText,
                feedback:translation.feedback
            };
            return y;
        })

       return translations;
    }

    GetOptionTranslation(lang:string,option:QuesOptions):QuesOptTranslation {
        var res = option.translations.filter((x) => {
            return x.lang === lang;
        })

        if (res.length > 0) {
            return res[0];
        } else {
            return option.translations[0];
        }
        
        return new QuesOptTranslation;
    }

    GetSolution(lang:string):QuesSolution {
        var res = this.solution.filter((x) => {
            return x.lang === lang;
        })
        if (res.length > 0) {
            return res[0];
        } else {
            return this.solution[0];
        }
        return new QuesSolution;
    }
}

type OptionView={
    optionID: number,
    fraction: number, 
    optionText: string;
    lang: string ;
    feedback: string
}