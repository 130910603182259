import React, { useState, useEffect } from "react";

type OptionFixedProps = {
  name: string;
  data: any;
  func: Function;
};

function OptionFixed(props: OptionFixedProps) {
  // console.log(props.name);

  const data = props.data;

  const [isSelected, setIsSelected] = useState<number>(-1);
  const [className, setClassName] = useState<string>(
    "border border-dark rounded-pill m-1 p-2"
  );
  const [onFilter, setOnFilter] = useState<number>(-1);

  const handleSelect = (id: number) => {
    if (isSelected >= 0 && isSelected == id) {
      setIsSelected(-1);
    } else if (isSelected >= 0 && isSelected != id) {
      setIsSelected(id);
    } else {
      setIsSelected(id);
    }
  };

  const handleResetBtn = () => {
    setIsSelected(-1);
    setClassName("border border-dark rounded-pill m-1 p-2");
  };

  const handleApplyBtn = () => {
    setOnFilter(isSelected);
  };

  const name = props.name.replace(/ /g, "");
  const d = "#" + name;
  const id = name;
  const label = name + "Label";

  useEffect(() => {
    props.func(name, data[onFilter]);
  }, [onFilter]);

  return (
    <>
      <div className="col-md-6 py-2">
        <div className="d-flex justify-content-between align-items-center bg-danger rounded-top p-2">
          <span>{props.name}</span>
          <span
            className="user-select-none"
            data-bs-toggle="modal"
            data-bs-target={d}
          >
            filter
          </span>
        </div>
        <div className="d-flex justify-content-between bg-warning rounded-bottom p-2">
          <div
            className="modal fade"
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby={label}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={label}></h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body d-flex flex-wrap justify-content-c">
                  {props.data.map((item: any) => {
                    return (
                      <p
                        key={item.id}
                        className={`${className} ${
                          isSelected === item.id ? "bg-info" : ""
                        }`}
                        onClick={(e) => handleSelect(item.id)}
                      >
                        {item.name}
                      </p>
                    );
                  })}
                </div>
                <div className="modal-footer rounded-pill p-1 shadow m-2 mx-auto bg-light w-40 d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => handleResetBtn()}
                    className="btn rounded-pill"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={() => handleApplyBtn()}
                    className="btn btn-primary rounded-pill"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap bg-warning rounded-bottom">
            {onFilter >= 0 && (
              <div
                key={data[onFilter].id}
                className="border border-dark rounded-pill m-1 px-2"
              >
                {data[onFilter].name}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(OptionFixed);
