import React from 'react'
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

type TimerProps = {
  expiryTimestamp: Date
  sec: number
  hideTimer: any
}

function Timer(props:TimerProps) {

  const { expiryTimestamp, sec, hideTimer } = props;

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
  
  return (
    <>
    {
      (!seconds) && hideTimer(0, false)
    }
    <div style={{textAlign: 'center', width: '100vw'}}>
      <div style={{fontSize: '40px', color: 'red'}}>
        <span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
      {/* <button onClick={start}>Start</button>       */}
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button>
      <button onClick={() => {
        // Restarts to 5 minutes timer
        let second = sec;
        const time = new Date();
        time.setSeconds(time.getSeconds() + second);
        restart(time);      
      }}>Restart</button>
    </div>
    </>
  )
}
export default React.memo(Timer);
