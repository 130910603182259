import React from 'react'
import parse from 'html-react-parser'
import { SearchQuesitonDto } from '../../../dtos/searchQuestions/SearchQuestionsDto';

type QuestionProps = {
  ques: SearchQuesitonDto
  no: number
  lang: boolean
  color: string
  background: string
}

function Questions(props:QuestionProps) {
  const {ques, no, lang, color, background} = props;

  let Q:any = ques.questionTitle;
  if (!lang) {
    Q = Q.filter((x:any)=>x.lang==="en");
    if (Q.length === 0) {
      Q = ques.questionTitle[0];
    }
  }

  return (
    <div style={{color: `${color}`, background: `${background}` }}>
      <p>Question</p>
      {lang
      ? Q.map((question: { questionText: string; }, index: React.Key | null | undefined) => {
        return (
          <p key={index}>{parse(question.questionText)}</p>
        )
      })
      : Q.map((question: { questionText: string; }, index: React.Key | null | undefined) => {
        return (
          <p key={index}>{parse(question.questionText)}</p>
        )
      })}
    </div>
  )
}

export default React.memo(Questions);