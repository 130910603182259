import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaHome } from "react-icons/fa";

import OptionUsingApi from "./components/OptionUsingApi";
import NoOfRecord from "./components/NoOfRecord";
import OptionFixed from "./components/OptionFixed";
import Range from "./components/Range";
import { Type, plainToClass, plainToInstance } from "class-transformer";
import { SearchQuesitonDto } from "../../dtos/searchQuestions/SearchQuestionsDto";

type onFilter = {
  id?: number;
  name: string;
}[];

type ValueState = {
  NoOfRecord: onFilter;
  Exam: onFilter;
  Year: onFilter;
  Subject: onFilter;
  Topic: onFilter;
  Chapter: onFilter;
  QuestionNature: onFilter;
  UsedBefore: onFilter;
  OrderBy: onFilter;
  LevelRange: onFilter;
  ImportanceLevel: onFilter;
};

function FilterPage() {
  const filterProp = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<SearchQuesitonDto[]>();
  const [value, setValue] = useState<ValueState>({
    NoOfRecord: [{id:0, name: "100"}],
    Exam: [],
    Year: [],
    Subject: [],
    Topic: [],
    Chapter: [],
    QuestionNature: [],
    UsedBefore: [],
    OrderBy: [],
    LevelRange: [],
    ImportanceLevel: [],
  });
  const orderBy = [
    { id: 0, name: "None" },
    { id: 1, name: "Random" },
    { id: 2, name: "Importance" },
    { id: 3, name: "Level" },
    { id: 4, name: "Previous Year" },
  ];
  const useBefore = [
    { id: 0, name: "No Preference" },
    { id: 1, name: "Never Used" },
    { id: 2, name: "Previously Used" },
  ];

  const updateValue = (filter: String, val: onFilter) => {
    if (filter === "Exam") {
      setValue({ ...value, Exam: val });
    } else if (filter === "Previous Year") {
      setValue({ ...value, Year: val });
    } else if (filter === "Subject") {
      setValue({ ...value, Subject: val });
    } else if (filter === "Topic") {
      setValue({ ...value, Topic: val });
    } else if (filter === "Chapter") {
      setValue({ ...value, Chapter: val });
    } else if (filter === "Question Nature") {
      setValue({ ...value, QuestionNature: val });
    } else if (filter === "UsedBefore") {
      setValue({ ...value, UsedBefore: val });
    } else if (filter === "OrderBy") {
      setValue({ ...value, OrderBy: val });
    } else if (filter === "LevelRange") {
      setValue({ ...value, LevelRange: val });
    } else if (filter === "ImportanceLevel") {
      setValue({ ...value, ImportanceLevel: val });
    } else if (filter === "NoOfRecords") {
      setValue({ ...value, NoOfRecord: val });
    }
    // console.log(filter, val);
  };

  const getRespectiveQuestions = async () => {
    let PreviousYear = value.Year?.map((u) => u.name).join(",");
    let LevelRange = "0,"+value.LevelRange?.map((u) => u.name).join(",");
    let ImportanceLevel = "0,"+value.ImportanceLevel?.map((u) => u.name).join(",");
    let Subjects = value.Subject?.map((u) => u.name).join(",");
    let Chapter = value.Chapter?.map((u) => u.name).join(",");
    let Topic = value.Topic?.map((u) => u.name).join(",");
    let ExamsId = value.Exam?.map((u) => u.id).join(",");
    let OrderBy = Object.values(value.OrderBy);
    let NoOfRecords = value.NoOfRecord[0]?.name;
    let UsedBefore = Object.values(value.UsedBefore);
    let Natures = value.QuestionNature?.map((u) => u.name).join(",");
    // console.log(typeof(LevelRange));
    // return;

    try {
      const data = await axios.post(
        "https://freekey-api.bitss.in/api/base/SearchQuestions",
        {
          PreviousYear: PreviousYear,
          LevelRange: "0,5",
          ImportanceLevel: "0,5",
          Subjects: Subjects,
          Chapter: Chapter,
          Topic: Topic,
          ExamsId: ExamsId,
          OrderBy: OrderBy[1],
          NoOfRecords: NoOfRecords,
          UsedBefore: UsedBefore[1],
          Natures: Natures,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
          },
        }
      );
      setQuestions(data.data);
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (questions) {
      navigate("/selectionpage", {
        state: { setId: filterProp.state.setId, setName: filterProp.state.setName, ques: questions, redirect: "filterPage" },
      });
    }
  }, [questions]);

  return (
    <>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <FaHome style={{ fontSize: "25px", paddingBottom: "7px" }} />
            Home{" "}
          </Link>
        </div>
      </nav>
      <div className="container">
        <div className="row mb-4">
          <h2>Apply filters:</h2>
          <OptionFixed name={"Order By"} data={orderBy} func={updateValue} />
          <NoOfRecord name={"No Of Records"} func={updateValue} />
          <OptionFixed
            name={"Used Before"}
            data={useBefore}
            func={updateValue}
          />

          <Range name={"Level Range"} func={updateValue} />
          <Range name={"Importance Level"} func={updateValue} />

          <OptionUsingApi
            name={"Exam"}
            url={"https://freekey-api.bitss.in/api/base/getexamnames"}
            func={updateValue}
          />
          <OptionUsingApi
            name={"Previous Year"}
            url={"https://freekey-api.bitss.in/api/base/GetPreviousYears"}
            func={updateValue}
          />
          <OptionUsingApi
            name={"Subject"}
            url={"https://freekey-api.bitss.in/api/base/GetSubjects"}
            func={updateValue}
          />
          <OptionUsingApi
            name={"Chapter"}
            url={"https://freekey-api.bitss.in/api/base/GetChapters/?Subject=Math"}
            func={updateValue}
          />
          <OptionUsingApi
            name={"Question Nature"}
            url={"https://freekey-api.bitss.in/api/base/GetQuestionNatures"}
            func={updateValue}
          />
          <OptionUsingApi
            name={"Topic"}
            url={
              "https://freekey-api.bitss.in/api/base/GetTopics/?Subject=maths&chapter=cs"
            }
            func={updateValue}
          />
        </div>
        <div className="row d-flex justify-content-center">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="col-md-6 text-center mb-5">
              <button
                className="btn btn-primary is-loading"
                onClick={() => getRespectiveQuestions()}
              >
                Get the Questions
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(FilterPage);