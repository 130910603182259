import React from 'react'

type AnswerKeyWidget = {
    anskey:any
}

function AnswerKeyWidget(props:AnswerKeyWidget) {
    // console.log(anskey);
    const handleAnswer = (ques:string, ans:{fraction:number}[] ) => {
        let anstr = ques + " (";
        ans.forEach((el, index) => {
            if(el.fraction === 1) {
                if (index === 0){
                    anstr = anstr + 'a, ';
                } else if (index === 1){
                    anstr = anstr + 'b, ';
                } else if (index === 2){
                    anstr = anstr + 'c, ';
                } else if (index === 3){
                    anstr = anstr + 'd, ';
                } 
            }
        });
        anstr = anstr.slice(0, -2);
        anstr = anstr + ')';
        return anstr;
    }
    const style = {
        display: "inline-block",
        border: "solid 1px rgb(123 120 120)",
        background: "#f0f0f0",
        width: "80px",
        padding: "5px",
        margin: "3px",
      };
    return (
        <div className='my-3'>
            <h4 className='text-center pb-3'>Answer Key</h4>
            <div className=''>
                {props.anskey.map((item:any) => {
                    return (
                        <p className='text-center fw-bold' key={item.questionNo} style={style}>
                            {handleAnswer(item.questionNo, item.options)}
                        </p>
                    )
                })}
            </div>
        </div>
    )
}
export default React.memo(AnswerKeyWidget);
