import React from 'react'
import parse from 'html-react-parser'
import { QuesSolution } from '../../../dtos/searchQuestions/QuesSolution'

type ExplanationProps = {
  exp:QuesSolution[] 
  show: boolean 
  lang: boolean 
  color: string 
  background: string 
}

function Explanation(props:ExplanationProps) {
  const {exp, show, lang, color, background} = props;
  let e:any ;
  if (!lang) {
    e = exp.filter((x:any) => x.lang === 'en');
    if (exp.length === 0) {
      e = exp[0];
    }
  }
  return (
    <div style={{color: `${color}`, background: `${background}` }}>
      Explanation
      {show && lang && exp.map((explanation: { solutionText: string }, index: React.Key | null | undefined) => {
        return (
          <article key={index}>{parse(explanation.solutionText)}</article>
        )
      })}
      {show && !lang && e.map((explanation: { solutionText: string }, index: React.Key | null | undefined) => {
        return (
          <article key={index}>{parse(explanation.solutionText)}</article>
        )
      })}
    </div>
  )
}

export default React.memo(Explanation);
