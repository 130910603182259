import React from 'react'
import parse from 'html-react-parser'

type QuestionProps = {
    ques: any
    multilingual: boolean
    fontSize: number | string
}

function Question(props:QuestionProps) {
    
    let qt:any[] = [];
    if (!props.multilingual) {
        qt = (props.ques.questionTitle).filter((x: { lang: string }) => x.lang==="en");
        if (qt.length === 0) {
            qt = props.ques.questionTitle[0];
        }
    }

    return (
        <div className='d-flex flex-row'>
            <div>
                <p key={props.ques.questionNo}><b>{props.ques.questionNo}.</b></p>
            </div>
            <div className='px-2 text-danger'>
                {/* <p> */}
                    {props.multilingual && (props.ques.questionTitle).map((q:any, index:number) => {
                        return (
                            <span key={index}>{parse(q.questionText)}<br /></span>
                        )
                    })}
                    {!props.multilingual && qt.map((q, index) => {
                            return (
                                <span key={index}>{parse(q.questionText)}<br /></span>
                            )
                        })
                    }

                {/* </p> */}
            </div>
        </div>
    )
}
export default React.memo(Question);
