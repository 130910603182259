import React from 'react'
import axios from "axios";
import { useLocation } from 'react-router-dom';

//Import components
import Questions from './components/Questions'
import Options from './components/Options'
import Explanation from './components/Explanation'
// import AutoPlaySecondBox from './components/AutoPlaySecondBox'
// import JumpQuestionsModal from './components/JumpQuestionsModal'
import SettingModal from './components/SettingModal'
import Timer from './components/Timer'

import { IoMdImages, IoIosBook } from 'react-icons/io'
import { LiaListOlSolid} from 'react-icons/lia'
import { FcSettings } from 'react-icons/fc'
import { RxTimer } from 'react-icons/rx'
import { BsFillCollectionPlayFill } from 'react-icons/bs'
import { FaLongArrowAltLeft, FaLongArrowAltRight, FaPlus, FaMinus, FaLanguage } from "react-icons/fa";

import { Rnd } from "react-rnd";
import { useState, useEffect } from 'react';
import { Search } from 'react-router-dom'
import { SearchQuesitonDto } from '../../dtos/searchQuestions/SearchQuestionsDto'

type PresentationPageProps = {
  url: string
}
function PresentationPage(props:PresentationPageProps) {
  let presentationpageData = useLocation();
  const [data, setData] = useState<SearchQuesitonDto[]>(presentationpageData.state.ques);

  useEffect(() => {
    axios
      .get<any>(`${props.url}`)
      .then((res) => {
        return setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // const {data} = props;

  const [questionNo, setQuestionNo] = useState(0);

  const nextQuestion = (e:any) => {
    setQuestionNo(questionNo + 1);
  };
  const prevQuestion = (e:any) => {
    setQuestionNo(questionNo - 1);
  };
  const [font, setFont] = useState(18);
  const incFont = () => {
    setFont(font + 2);
  }
  const decFont = () => {
    setFont(font - 2);
  }

  type dndPositions = {
    width: number|string
    height: number|string
    x: number
    y: number
  }

  const [quState, setQuState] = useState<dndPositions>({
    width: 800,
    height: 'auto',
    x: 100,
    y: 100,
  });
  const [opState, setOpState] = useState<dndPositions>({
    width: 400,
    height: 'auto',
    x: 100,
    y: 250,
  });
  const [exState, setExState] = useState<dndPositions>({
    width: 600,
    height: 200,
    x: 500,
    y: 250,
  });
  
  const style = {
    display: "block",
    // alignItems: "center",
    justifyContent: "start",
    padding: "10px",
    border: "solid 1px #ddd",
    // background: "#f0f0f0",
    width: "800px !important",
    fontSize: `${font}px`
  };

  const handleImages = () => { }
  const [showExp, setShowExp] = useState(false);
  const handleShowExp = () => {
    {showExp 
      ? setShowExp(false)
      : setShowExp(true)
    }
  }
  const [showOpt, setShowOpt] = useState(true);
  const handleShowOpt = () => {
    {showOpt 
      ? setShowOpt(false)
      : setShowOpt(true)
    }
  }
  const [multiLang, setMultiLang] = useState(true);
  const handleMultilingual = () => {
    {multiLang 
      ? setMultiLang(false)
      : setMultiLang(true)
    }
  }

  const [questionTextColor, setQuestionTextColor] = useState('red');
  const [questionBackgroundColor, setQuestionBackgroundColor] = useState('white');
  const [optionTextColor, setOptionTextColor] = useState('black');
  const [optionBackgroundColor, setOptionBackgroundColor] = useState('white');  
  const [explanationTextColor, setExplanationTextColor] = useState('black');
  const [explanationBackgroundColor, setExplanationBackgroundColor] = useState('white');
  const [screenBackgroundColor, setScreenBackgroundColor] = useState('black');

  const [sec, setSecs] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const showTheTimer = (time: React.SetStateAction<number>, state: boolean | ((prevState: boolean) => boolean)) => {
    if (state) {
      setSecs(0);
    }
    setSecs(time);
    setShowTimer(state);
  }
  const timer = new Date();
  timer.setSeconds(timer.getSeconds() + sec);
  let length = data?.length;
  let interval: string | number | NodeJS.Timeout | undefined;
  const [autoPlay, setAutoPlay] = useState(false);
  const handleAutoPlay = () => {
    console.log('start');
    (autoPlay == true) ? setAutoPlay(false) : setAutoPlay(true);
    if (autoPlay) {
      interval = setInterval(() => {
        setQuestionNo((questionNo) => questionNo + 1)
        setAutoPlay(!autoPlay);
        console.log(questionNo);
      }, 1000);
    } 
    // else {
    //   clearInterval(interval);
    // }
  }
  const handleStopPlay = () => {
    console.log('stop');

    clearInterval(interval);
  }
  
  let d ;
  if(data) {
    d = data[questionNo];
  }
  useEffect(() => {
    // console.log(timer);
    setTimeout(function(){  (window as any).MathJax.typesetPromise(); }, 10);    
  }, [font, questionNo, questionTextColor, questionBackgroundColor, optionTextColor, optionBackgroundColor, explanationTextColor, explanationBackgroundColor, screenBackgroundColor, sec, showTimer, autoPlay])

  return (
    <div className='container-fluid' style={{minHeight: '100vh', maxHeight: '100%', maxWidth: '100%', backgroundColor: `${screenBackgroundColor}` }}>
      <h5 className='pt-5 text-center'>Question NO: {questionNo + 1}</h5>
      <Rnd
        style={style}
        size={{ width: quState.width, height: quState.height }}
        position={{x: quState.x, y: quState.y}}
        onDragStop={(e, d) => {
          setQuState({...quState,  x: d.x, y: d.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setQuState({
            width: ref.style.width,
            height: ref.style.height,
            ...position,
          });
        }}
      >
        {d && <Questions ques={d} no={questionNo + 1} lang={multiLang} color={questionTextColor} background={questionBackgroundColor} />}
      </Rnd>
      {showOpt && <Rnd
        style={style}
        size={{ width: opState.width, height: opState.height }}
        position={{x: opState.x, y: opState.y}}
        onDragStop={(e, d) => {
          setOpState({...opState, x: d.x, y: d.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setOpState({
            width: ref.style.width,
            height: ref.style.height,
            ...position,
          });
        }}
      >
        {d && <Options opt={d.options}  show={showOpt} lang={multiLang} color={optionTextColor} background={optionBackgroundColor} />}
      </Rnd>
      }      
      {showExp && <Rnd
        style={style}
        size={{ width: exState.width, height: exState.height }}
        position={{x: exState.x, y: exState.y}}
        onDragStop={(e, d) => {
          setExState({...exState, x: d.x, y: d.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setExState({
            width: ref.style.width,
            height: ref.style.height,
            ...position,
          });
        }}
      >
        {d && <Explanation exp={d.solution} show={showExp} lang={multiLang} color={explanationTextColor} background={explanationBackgroundColor} />}
      </Rnd>
      }
      
      <nav className="navbar fixed-bottom navbar-secondary ">
        <div className="container bg-secondary p-1 .dropdown-item-text.">
            <button onClick={prevQuestion}><FaLongArrowAltLeft style={{fontSize: '25px'}} /></button>
            <button onClick={nextQuestion}><FaLongArrowAltRight style={{fontSize: '25px'}} /></button>
            <button onClick={incFont}><FaPlus style={{fontSize: '25px'}} /></button>
            <button onClick={decFont}><FaMinus style={{fontSize: '25px'}} /></button>
            <button type="button" className="" data-bs-toggle="modal" data-bs-target="#settingsModal">
              <FcSettings style={{fontSize: '25px'}} />
            </button>
            <button className='' onClick={handleImages}><IoMdImages style={{fontSize: '25px'}} /></button>
            <button className='' onClick={handleShowExp}><IoIosBook style={{fontSize: '25px'}} /></button>
            <button className='' onClick={handleShowOpt}><LiaListOlSolid style={{fontSize: '25px'}} /></button>
            <button className='' onClick={handleMultilingual}><FaLanguage style={{fontSize: '25px'}} /></button>
            
            <div className="btn-group dropup">
              <button type="button" className="" data-bs-toggle="dropdown" aria-expanded="false">
                <RxTimer style={{fontSize: '25px'}} />
              </button>
              <ul className="dropdown-menu mb-2 w-100">
                <li><button className='mx-1' onClick={ () => showTheTimer(10, true) }>10sec</button><button className='mx-1' onClick={ () => showTheTimer(15, true) }>15sec</button></li>
                <li><button className='mx-1' onClick={ () => showTheTimer(30, true) }>30sec</button><button className='mx-1' onClick={ () => showTheTimer(45, true) }>45sec</button></li>
                <li><button className='mx-1' onClick={ () => showTheTimer(50, true) }>50sec</button><button className='mx-1' onClick={ () => showTheTimer(60, true) }>60sec</button></li>
              </ul>
            </div>
            <button onClick={handleAutoPlay}>< BsFillCollectionPlayFill /></button>
            <button onClick={handleStopPlay}>stop</button>

            {/* <AutoPlaySecondBox /> */}
        </div>
      </nav>

      {showTimer && 
        <Timer expiryTimestamp={timer} sec={sec} hideTimer={showTheTimer}/>
      }
      <SettingModal 
        setQuestionBackgroundColor={setQuestionBackgroundColor}
        setQuestionTextColor={setQuestionTextColor}
        setOptionBackgroundColor={setOptionBackgroundColor}
        setOptionTextColor={setOptionTextColor}
        setExplanationBackgroundColor={setExplanationBackgroundColor}
        setExplanationTextColor={setExplanationTextColor}
        setScreenBackgroundColor={setScreenBackgroundColor}
      />

    </div>
  )
}

export default React.memo(PresentationPage);
