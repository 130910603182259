import React from "react";

type headerProps = {
  h3: string;
  span1: string;
  span2: string;
  span3: string;
};

function Header(props: headerProps) {
  return (
    <div className="preview_header text-center pt-1">
      <h3 className="">{props.h3}</h3>
      <p className="text-white ">
        <small>
          <span className="border border-2 rounded-pill border-white px-2 pb-1">
            {props.span1}
          </span>{" "}
          <span className="border border-2 rounded-pill border-white px-2 pb-1">
            {props.span2}
          </span>{" "}
          <span className="border border-2 rounded-pill border-white px-2 pb-1">
            {props.span3}
          </span>
        </small>
      </p>
    </div>
  );
}
export default React.memo(Header);
