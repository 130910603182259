// export class x{

// }
import React from "react";
import parse from "html-react-parser";

type OptionsProps = {
  opt: any;
  multilingual: boolean;
  ans: any;
};

function Options(props: OptionsProps) {
  let en = 0;
  let l = props.opt[1].translations[0].optionText.length;
  return (
    <div className="d-flex flex-row">
      <div></div>
      <div className="px-3 w-100">
        <ol className="row pl-6" type="a">
          {props.multilingual &&
            props.opt.map((options: any, index: number) => {
              return (
                <li
                  className={l > 30 ? "col-md-12" : "col-md-6"}
                  key={options.optionID}
                  data-iscorrect={options.fraction}
                >
                  {props.ans && options.fraction
                    ? options.translations.map((t: any) => {
                        return (
                          <p key={index}>
                            <span className="bg-warning p-1">
                              {parse(t.optionText)}
                            </span>
                          </p>
                        );
                      })
                    : options.translations.map((t: any) => {
                        return (
                          <p className="" key={index}>
                            {parse(t.optionText)}
                          </p>
                        );
                      })}
                </li>
              );
            })}

          {!props.multilingual &&
            props.opt.map(({ options, index }: any) => {
              let trans = options.translations;
              trans = trans.filter((x: { lang: string }) => x.lang === "en");
              if (trans.length === 0) {
                trans = [options.translations[0]];
              }
              return (
                <li
                  className={l > 25 ? "col-md-12" : "col-md-6"}
                  key={options.optionID}
                  data-iscorrect={options.fraction}
                >
                  {trans.map((t: { optionText: string }) => {
                    en = 1;
                    let cssClasses = "";
                    if (props.ans && options.fraction === 1) {
                      cssClasses = "bg-warning me-2 ps-2";
                    }
                    return (
                      <p key={index}>
                        <span className={cssClasses}>
                          {parse(t.optionText)}
                        </span>
                      </p>
                    );
                  })}
                </li>
              );
            })}
        </ol>
      </div>
    </div>
  );
}

export default React.memo(Options);
