import React from "react";
import parse from "html-react-parser";
import { QuesSolution } from "../../../dtos/searchQuestions/QuesSolution";

type SolutionProps = {
  sol: QuesSolution[];
  multilingual: boolean;
};

function Solution(props: SolutionProps) {
  const { sol, multilingual } = props;
  let s: any;
  if (!multilingual) {
    s = sol.filter((x: any) => x.lang === "en");
    if (s.length === 0) {
      s = sol[0];
    }
  }
  return (
    <div className="p-2 border border-danger border-2 col-12">
      <p className="bg-danger text-white text-center fw-bold">
        <b>Solution:</b>
      </p>
      <p>
        {multilingual &&
          sol.map((solution, index) => {
            return <span key={index}>{parse(solution.solutionText)}</span>;
          })}
        {!multilingual &&
          s.map((solution: any, index: number) => {
            return <span key={index}>{parse(solution.solutionText)}</span>;
          })}
      </p>
    </div>
  );
}
export default React.memo(Solution);
