import React from 'react'
import parse from 'html-react-parser'
import { QuesOptions } from '../../../dtos/searchQuestions/QuesOptions';

type OptionsProps = {
  opt: QuesOptions[]
  show: boolean
  lang: boolean
  color: string
  background: string
}

function Options(props:OptionsProps) {
  const {opt, show, lang, color, background} = props;

  let op = opt;

  return (
    <div style={{color: `${color}`, background: `${background}`}}>
      Options
      <ol type='a'>
        {(show && lang) && op.map((option) => {
          return (
            <li key={option.optionID}>
                {option.translations.map((o, index) => {
                  return <p key={index}>{parse(o.optionText)}</p>
                })}
            </li>
          )
        })}
        {(show && !lang) && op.map((option) => {
          let trans =  option.translations.filter(x=>x.lang==="en");
          if(trans.length===0){
             trans = [option.translations[0]];
          }
          return (
            <li key={option.optionID}>
              { trans.map((t, index) => {
                return (
                  <p key={index}>{parse(t.optionText)}</p>
                )
              })
              }
            </li>
          )
        })}

      </ol>
    </div>
  )
}

export default React.memo(Options);
