import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { FaHome } from "react-icons/fa";

import { RiSave3Fill } from "react-icons/ri";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

import { useNavigate, useLocation } from "react-router";

import { SearchQuesitonDto } from "../../dtos/searchQuestions/SearchQuestionsDto";
import { plainToInstance } from "class-transformer";

type OptionView = {
  optionID: number;
  fraction: number;
  optionText: string;
  lang: string;
  feedback: string;
};

function QuesSelectionPage() {
  const quesSelect = useLocation();
  const navigateTo = useNavigate();
  const setName = quesSelect.state.setName;
  const setID = quesSelect.state.setId;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchQuesitonDto[]>(quesSelect.state.ques);
  const [questionObj, setQuestionObj] = useState<SearchQuesitonDto[]>([]);
  const [currIndex, setCurrIndex] = useState<number>(0);
  const [isRefreshView, setisRefreshView] = useState<boolean>(false);
  const [totalSelected, setTotalSelected] = useState<any>(0);
  const [selectedArr, setSelectedArr] = useState<number[]>([]);
  const [lang, setLang] = useState("en");

  function shuffleQuestions(array: any[]) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle Questions.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const selectAllToggle = (e: any) => {
    var isChecked = e.target.checked;
    if (isChecked) {
      setTotalSelected(questionObj.length);
      questionObj.map((a: SearchQuesitonDto, i) => {
        a.isSelected = true;
      });
      setisRefreshView(true);
    } else {
      questionObj.map((a: SearchQuesitonDto, i) => {
        a.isSelected = false;
      });
      setTotalSelected(0);
    }
  };
  const ShuffelBtn = () => {
    shuffleQuestions(questionObj);
    setisRefreshView(true);
  };
  const filterBtn = () => {
    navigateTo("/filterpage", {
      state: { setId: setID, setName: setName },
    });
  };

  const SelectQues = (e: any) => {
    if (!e.target.checked) {
      // alert("toggle");
    }

    questionObj[currIndex].isSelected = !questionObj[currIndex].isSelected;
    CalculateTotalSelected();
    setisRefreshView(true);
  };

  const CalculateTotalSelected = () => {
    let t = 0;
    questionObj.map((a: SearchQuesitonDto, i) => {
      if (a.isSelected === true) {
        t++;
      }
    });
    setTotalSelected(t);
  };

  const changeLang = (e: any) => {
    setLang(e.target.value);
    // console.log(lang);
    setisRefreshView(true);
  };
  const swapQuestion = (n: number) => {
    let currItem = questionObj[currIndex];
    questionObj[currIndex] = questionObj[n];
    questionObj[n] = currItem;
    setCurrIndex(n);
    setisRefreshView(true);
  };

  const nextQuestion = () => {
    if (currIndex < questionObj.length - 1) {
      setCurrIndex(currIndex + 1);
    }
  };
  const prevQuestion = () => {
    if (currIndex > 0) {
      setCurrIndex(currIndex - 1);
    }
  };

  type saveQuesSetProp = {
    setID: string;
    sQuestion: [
      {
        questionID: string;
        serialNumber: number;
      }[]
    ];
  };

  selectedArr.map((item: any, index: number) => {
    let a = { questionID: item, serialNumber: index };
  });

  let a = {
    setID: "",
    sQuestion: [
      {
        questionID: 6,
        serialNumber: 1,
      },
    ],
  };

  const saveSelectedQues = async () => {
    setIsLoading(true);

    let selectedqs = questionObj
      .filter((x) => {
        return x.isSelected == true;
      })
      .map((item: SearchQuesitonDto, index: any) => {
        return {
          questionID: item.questionID,
          serialNumber: index + 1,
        };
      });
    let selectedqs1: number[] = [];

    let selectedqs_final: { questionID: number; serialNumber: number }[] = [];

    selectedqs.forEach((element) => {
      if (!selectedqs1.includes(element.questionID)) {
        selectedqs_final.push(element);
        selectedqs1.push(element.questionID);
      }
    });

    a.setID = setID;
    a.sQuestion = selectedqs_final;

    // console.log(a);
    // return;

    try {
      const data = await axios.post(
        "https://ms-teachami.prepami.com/api/question/SaveQuestionSet",
        a,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW5AZ21haWwuY29tIiwib3JnaWQiOiIyIiwiZXhwIjoxNzIwMDQ2NTc4LCJpc3MiOiJQcmVwQW1pQVBJIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eS5wcmVwYW1pLmNvbSJ9.syt_XQHUMqaUaa_J9vcjvytGYF4NSD0lNHGB-NabAfo"
      
          },
        }
      );

      // setQuesSet(data.data);
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    var d = plainToInstance(SearchQuesitonDto, data);
    if (quesSelect.state.redirect === "homePage") {
      // d[currIndex].isSelected = true;

      d.forEach((element) => {
        element.isSelected = true;
      });
    }
    setQuestionObj(d);
  }, [data]);

  useEffect(() => {
    setTimeout(function () {
      (window as any).MathJax.typesetPromise();
      CalculateTotalSelected();
    }, 10);
  }, [questionObj, currIndex, lang]);

  useEffect(() => {
    console.log(selectedArr);
  }, [selectedArr]);

  useEffect(() => {
    if (isRefreshView) {
      setisRefreshView(false);
    }
  }, [isRefreshView]);

  if (questionObj.length > 0 && currIndex < questionObj.length) {
    let quesTitle = questionObj[currIndex].GetQuestionTitle(lang);
    let quesOption = questionObj[currIndex].GetOptions(lang);
    let quesSolution = questionObj[currIndex].GetSolution(lang);

    return (
      <div className="container selection_page">
        <div className="header p-2 bg-warning rounded-bottom fixed-top">
          <p className="fs-4 text-center m-0 p-0">
            <Link to="/" className="">
              <FaHome
                style={{
                  fontSize: "25px",
                  paddingBottom: "7px",
                  color: "#111",
                }}
              />
            </Link>
            {setName}
          </p>
          <br></br>
          <div className="overflow-auto d-flex justify-content-center align-items-center pt-0 pb-2">
            <span className="border border mx-1 px-1 border-secondary bg-light d-inline ">
              Total: {totalSelected}
            </span>
            <span className="d-inline-block mx-1 form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="select-all-question"
                onChange={(e) => selectAllToggle(e)}
              />
              <label className="form-check-label" htmlFor="select-all-question">
                Select All
              </label>
            </span>
            <span
              onClick={() => ShuffelBtn()}
              className="mx-1 px-2 border border-dark rounded-pill"
            >
              Shuffle
            </span>
            <span
              onClick={() => filterBtn()}
              className="mx-1 px-2 border border-dark rounded-pill"
            >
              Filter
            </span>
          </div>

          <div className="overflow-auto d-flex justify-content-center align-items-center">
            <div className="form-check pe-2">
              <input
                className="form-check-input"
                id="select-question"
                onChange={(e) => SelectQues(e)}
                type="checkbox"
                checked={
                  questionObj[currIndex] != null &&
                  questionObj[currIndex].isSelected != null
                    ? questionObj[currIndex].isSelected
                    : false
                }
              />
              <label className="form-check-label" htmlFor="select-question">
                Selected
              </label>
            </div>
            <span className="d-inline">
              <select
                name="language"
                id="select-lang"
                onChange={(e) => changeLang(e)}
              >
                <option value="en">English</option>
                <option value="hn">Hindi</option>
              </select>
            </span>
            <span
              data-bs-toggle="modal"
              data-bs-target="#createQuesSet"
              className="mx-1 px-2 border border-dark rounded-pill"
            >
              Swap Ques.
            </span>
          </div>
        </div>

        <div
          className="modal fade"
          id="createQuesSet"
          tabIndex={-1}
          aria-labelledby="createQuesSetLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createQuesSetLabel">
                  Select another ques. to swap
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pb-5 d-flex flex-wrap">
                {questionObj?.map((item: SearchQuesitonDto, index: any) => {
                  return (
                    <span
                      className={`m-1 bg-warning rounded p-2 ${
                        currIndex === index
                          ? "border border-dark"
                          : ""
                      }`}
                      key={index}
                      onClick={() => swapQuestion(index)}
                    >
                      {index + 1}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {isLoading && (
          <div className="text-center my-5 py-5 ">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {!isLoading && questionObj && (
          <div className="body my-5 py-5 ">
            <div className="overflow-auto mt-4 py-5 text-center ">
              {questionObj?.map((item: SearchQuesitonDto, index: any) => {
                return (
                  <span
                    className={`me-1 p-3 bg-warning rounded ${
                      currIndex === index ? "border border-dark" : ""
                    }`}
                    key={"b-" + index}
                    onClick={() => setCurrIndex(index)}
                  >
                    {index + 1}
                  </span>
                );
              })}
            </div>

            <p className="mb-2" key={"q-" + currIndex}>              
              {currIndex + 1}. {parse(quesTitle?.questionText)}
            </p>
            <ol className="list-group list-group-numbered mb-2">
              {quesOption?.map((item: OptionView) => {
                return (
                  <li
                    key={"o-" + item.optionID}
                    className={`list-group-item my-2 ${
                      item.fraction === 1.0 ? "bg-warning" : ""
                    } rounded`}
                  >
                    {parse(item.optionText)}
                  </li>
                );
              })}
            </ol>
            <div className="accordion mb-2" id="">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    View Solution
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#view_solution"
                >
                  <div className="accordion-body" key={"s-" + currIndex}>
                    {parse(quesSolution?.solutionText)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer fixed-bottom bg-light border-top border-secondary p-2 d-flex justify-content-between">
          <button className="btn btn-primary" onClick={() => prevQuestion()}>
            <BiLeftArrow style={{ fontSize: "25px" }} /> prev
          </button>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => saveSelectedQues()}
            >
              Save <RiSave3Fill style={{ fontSize: "25px" }} />
            </button>
          )}
          <button className="btn btn-primary" onClick={() => nextQuestion()}>
            next <BiRightArrow style={{ fontSize: "25px" }} />
          </button>
        </div>
      </div>
    );
  } else {
    return <div>"No questions loaded"</div>;
  }
}

export default memo(QuesSelectionPage);
