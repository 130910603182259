import React, { useState } from 'react';
import {FcSettings } from 'react-icons/fc';
import { FaPaintBrush } from "react-icons/fa";

import { SketchPicker } from 'react-color'

function SettingModal(props:any) {
  
  const [targetElementIndex, setTargetElementIndex] = useState(0);
  function targetElement(targetElementIndex: React.SetStateAction<number>) {
    console.log(targetElementIndex);
    setTargetElementIndex(targetElementIndex);
    //document.getElementById(targetElement).style.backgroundColor = '#292929';
    removeBackgroundColor();
  }
  var removeBackgroundColor = () => {
    //document.getElementById(targetElement).style.backgroundColor = 'white';
  }
  const [color, setColor] = useState("black");
  var handleChangeComplete = (color: { hex: any; }) => {
    if (targetElementIndex == 0) {
        props.setQuestionTextColor(color.hex);
    } else if (targetElementIndex == 1) {
        props.setQuestionBackgroundColor(color.hex);
    } else if (targetElementIndex == 2) {
        props.setOptionTextColor(color.hex);
    } else if (targetElementIndex == 3) {
        props.setOptionBackgroundColor(color.hex);
    } else if (targetElementIndex == 4) {
        props.setExplanationTextColor(color.hex);
    } else if (targetElementIndex == 5) {
        props.setExplanationBackgroundColor(color.hex);
    } else if (targetElementIndex == 6) {
        props.setScreenBackgroundColor(color.hex);
    }
    console.log(color);
  };

  return (
    <>
      <div className="modal fade" id="settingsModal" tabIndex={-1} aria-labelledby="settingsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="settingsModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body row">
              <div className='col-12 m-2'>
                <button className='btn cfont1 btn-accent  gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(0) }} ><FaPaintBrush /> Quesiton Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(1) }} ><FaPaintBrush /> Question Background Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(2) }} ><FaPaintBrush /> Option Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(3) }} ><FaPaintBrush /> Option Background Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(4) }} ><FaPaintBrush /> Explanation Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(5) }} ><FaPaintBrush /> Explanation Background Color</button>
                <button className='btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]' onClick={ () => {targetElement(6) }} ><FaPaintBrush /> Screen Background Color</button>
              </div>
              <div className='col-6 m-2'>
                <SketchPicker color={color} onChange={({ hex }) => setColor(hex)} onChangeComplete={handleChangeComplete}/>
              </div>
              <div className='col-6 m-2'>
                
              </div>
            </div>
          </div>
        </div>
      </div>     
      
    </>
  )
}

export default React.memo(SettingModal);
